import CardMenu from "components/card/CardMenu";
import React, { useEffect, useState } from "react"
// import Checkbox from "components/checkbox";
// import { MdCheckCircle } from "react-icons/md";
import Card from "components/card";
import * as con from "../../../../repository/api_calls";
import { RiEdit2Line, RiDeleteBin2Line, RiEyeLine } from 'react-icons/ri';
import { Link, useNavigate } from "react-router-dom";

const TaskCard = (props) => {

  const navigate = useNavigate();

  function moveToPatientDetial(data) {
    navigate('/patient-detial', { state: data });
  }

  const userdata = JSON.parse(localStorage.getItem("userdata"));
  const userId = userdata.user.id 
  const userRoleId = localStorage.getItem('role_id');
  const [upcomingappointment, setUpcomingAppointment] = useState([]);

//   useEffect(() => {

//     const currentDate = new Date();
//     const seventhDate = new Date();

//     seventhDate.setDate(currentDate.getDate() + 7);


// // Get the year, month, and day
// const year = currentDate.getFullYear();
// const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
// const day = String(currentDate.getDate()).padStart(2, '0');
// const daySeven = String(seventhDate.getDate()).padStart(2, '0');

// // Format the date as yyyy-mm-dd
// const formattedDate = `${year}-${month}-${day}`;
// const toformattedDate = `${year}-${month}-${daySeven}`;

// console.log(formattedDate)
// const appointDate = {
//   // "from": "2024-03-14",
//   // "to": '2024-03-14'
//   "from": formattedDate,
//   "to": toformattedDate
// }
// console.log(appointDate)
// UpcomingAppointments(appointDate)

//     // getUpcomingAppointmentLists();
//   }, [props.userId, userRoleId]);

useEffect(() => {

  const currentDate = new Date();
    const seventhDate = new Date();


    seventhDate.setDate(currentDate.getDate() + 7);


    const fromYear = currentDate.getFullYear();
    const fromMonth = String(currentDate.getMonth() + 1).padStart(2, "0"); 
    const fromDay = String(currentDate.getDate()).padStart(2, "0");

    const toYear = seventhDate.getFullYear(); 
    const toMonth = String(seventhDate.getMonth() + 1).padStart(2, "0"); 
    const toDay = String(seventhDate.getDate()).padStart(2, "0");


    const formattedDate = `${fromYear}-${fromMonth}-${fromDay}`;
    const formattedSeventhDate = `${toYear}-${toMonth}-${toDay}`;

    const appointDate = {
      from: formattedDate,
      to: formattedSeventhDate,
    };
    console.log(appointDate)
    UpcomingAppointments(appointDate)

      
}, [props.userId, userRoleId]);


  // async function getUpcomingAppointmentLists() {
  //   const response = await con.upcomingAppointments();
  //   if (response['status'] === true) {
  //     console.log(response['data'])

  //     let filteredAppointments;

  //     if (userRoleId === '4') {
  //       // const mapID = response['data'].map((id) => id.id)
  //       filteredAppointments = response['data'].filter((appointment) => appointment.user_id === userId
  //       );
  //     } else if (userRoleId === '1' || userRoleId === '2' || userRoleId === '3') {
  //       filteredAppointments = response['data']
  //     }

  //     setUpcomingAppointment(filteredAppointments);
  //   }
  // }

  // UpcomingAppointmentAPI

  async function UpcomingAppointments(date) {
    
    

      const response = await con.UpcomingAppointmentAPI(date);
      // const response = await con.UpcomingAppointmentAPI((JSON.stringify(date)));
      console.log(response);
      if (response['status'] === true) {
        // setUpcomingAppointment(response['data'])

        
      let filteredAppointments;

      if (userRoleId === '4') {
        // const mapID = response['data'].map((id) => id.id)
        filteredAppointments = response['data'].filter((appointment) => appointment.user_id === userId
        );
      } else if (userRoleId === '1' || userRoleId === '2' || userRoleId === '3') {
        filteredAppointments = response['data']
      }

      setUpcomingAppointment(filteredAppointments)
        // toast.success(response['message'], {
        //   position: toast.POSITION.TOP_RIGHT,
        //   className: 'foo_bar_log_Success'
        // });

        // setTimeout(() => {
        //   window.location.reload();
        // }, 800);

      }
      else {
        console.log("Error:",  response.message)
        // toast.error(response['message'], {
        //   position: toast.POSITION.TOP_RIGHT,
        //   className: 'foo_bar_log_error'
        // })
      }
    
  }

  return (
    <Card extra="pb-7 p-[20px]" >
      {/* task header */}
      <div className="relative flex flex-row justify-between">
        <div className="flex items-center">

          <h4 className="ml-4 text-xl font-bold text-navy-700 dark:text-white" style={{ fontSize: "16px", marginTop: "6px", marginBottom: "20px" }}>
            Upcoming Appointments
          </h4>
        </div>
        {/* <CardMenu /> */}
      </div>


      <div className="list-container">
        <div className="list-view">
          <table className="bordered-table">
            <thead className="tablehead" >
              <tr style={{ borderRadius: "10px", color: "#808080", fontSize: "13px" }}>
                <th>S.No</th>
                <th>Patient </th>
                {/* <th>Doctor</th> */}
                <th>Date</th>
                <th>Time</th>
                {/* <th>Status</th> */}
              </tr>
            </thead>

            {upcomingappointment.map((appointment, index) => {
              // console.log(appointment.appointment_date)
              const dateObject = new Date(appointment.appointment_date);
              const year = dateObject.getFullYear();
              const month = String(dateObject.getMonth() + 1).padStart(2, '0');
              const day = String(dateObject.getDate()).padStart(2, '0');
              const formattedDate = `${day}-${month}-${year}`;
              return (
                <tbody key={index} style={{ color: "#1c1c20" }}>
                  {/* <tr> */}
                  <tr onClick={() => { moveToPatientDetial(appointment.appointments[0]); }} style={{ cursor: "pointer" }}>
                    <td>{index + 1}</td>
                    <td>{appointment.patient_first_name}</td>
                    {/* <td>{appointment.doctor_first_name}</td> */}
                    <td>{formattedDate}</td>
                    {/* <td>{appointment.appointments.map((d)=> d.appointment_time.split(""))}</td> */}
                    <td>{appointment.appointments.map((d) => d.appointment_time.split("").join("") + " , ")}</td>


                    {/* <td>{appointment.appointment_status}</td> */}
                  </tr>
                </tbody>
              )
            })}

          </table>

        </div>
      </div>
    </Card>
  );
};

export default TaskCard;
