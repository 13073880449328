import { useEffect, useState, useReducer, useContext } from 'react';
import * as con from '../../repository/api_calls';
import { UserContext } from "../../patient/exp";
import Book from './Book';
import GeneralDetails from './SummaryGeneralDetails';
import RescheduleAppoi from './reschedule_appoi';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { RiDeleteBin2Line, RiEdit2Line } from 'react-icons/ri';
import TreatmentPlanForm from './TreatmentPlanForm';

// function reducer(state, action) {
//   if (action.type === 'set_value') {
//     state[action.key] = action.value;
//     return {
//       appointment_status: state.appointment_status,
//       // id: state.id
//     };
//   }
// }


const DiagnosisSummary = (props) => {

  const userRoleId = localStorage.getItem('role_id');

  const contextValue = useContext(UserContext);

  const [firstDropDown, setFirstDropDown] = useState([]);
  const [formData, setFormData] = useState([]);
  const [editData, setEditData] = useState([]);

  function update(t, ind) {

  }

  const handleForEdit = (t, ind) => {
    t['index'] = ind;

    getTreatmentPlanName(t)
  }

  async function getTreatmentPlanName(t) {
    const response = await con.treatmentplanList();
    // console.log(response['data'])
    console.log(t.problem.id)
    if (response['status'] === true) {
      // const filteredDentalList = response['data']
      const filteredDentalList = response['data'].filter((d) => d.diagnosis_id.toString() === t.problem.id.toString())
      setFirstDropDown(filteredDentalList);
      setFormData(t)
      setEditData(t)
      console.log(filteredDentalList)
      // setOpen(true)


    }
  }



  const [open, setOpen] = useState(false)
  const [doctor, setDoctor] = useState([]);
  const [summary, setSummary] = useState([]);
  const [summaryT, setSummaryT] = useState([]);
  const [treatmentId, setTreatmentId] = useState([]);
  const [appointmentList, setAppointmentList] = useState([]);
  const [t, setT] = useState([]);

  // const patient = props.pData
  const patient = {
    data: props.pData,
    id: props.patient_id
  };

  const closeForm = (cls) => {
    setOpen(cls)
  }


  function set(t) {
    summary[t['index_1']]['is_booked'] = true;
    setSummary([...summary], summary)

  }


  function setres(tre) {
    appointmentList[tre['index_1']]['is_booked'] = true;
    setAppointmentList([...appointmentList], appointmentList)

  }
  // function set(t) {
  //   summary[t['index_1']]['is_booked'] = true;
  //   const gen = summary.filter((t) => t.is_booked !== true)
  //   const gen1 = summary.filter((t) => t.is_booked === true)
  //   setAppointmentList([...appointmentList], gen1);
  //   setSummary([...summary], gen);

  // }


  useEffect(() => {
    doctorsListAPI()
    appointmentListApi(patient.id)
    summaryList(patient.id)
  }, [])

  async function doctorsListAPI() {
    const response = await con.doctorsListAPI();
    if (response['status'] === true) {
      setDoctor(response['data']);

    }
  }


  async function summaryList(id) {
    const response = await con.summaryList();
    console.log(response);
    if (response['status'] === true) {

      const filteredId = response.data.filter((item) => item.patient_id === id).reverse();
      // const filteredId = response.data.filter((item) => item.patient_id === id && item.is_booked !== true);
      console.log(filteredId)

      setTreatmentId(filteredId)

      let localList = [];
      for (let i of filteredId) {
        console.log(i);
        if (i['patient_treatment'] != undefined || i['patient_treatment'] != "") {
          let li = JSON.parse(i['patient_treatment']);

          if (li !== null) {
            if (Array.isArray(li)) {
              for (let item of li) {
                item['created_at_1'] = i['created_at'];
                item['treatment_id'] = i['treatment_id']
                item['service_id'] = i['id']
                item['status'] = i['status']
                item['id'] = i['id']

              }
              localList.push(...li);
            } else {
              // Handle the case where li is not an array
              // For example, directly manipulate the object or push it into localList
              li['created_at_1'] = i['created_at'];
              li['treatment_id'] = i['treatment_id']
              li['service_id'] = i['id']
              li['status'] = i['status']
              li['id'] = i['id']
              localList.push(li);
            }
          }
        }



      }
      setSummary(localList);
    }
  }

  // ------------------------- Delete API for appoinment --------

  async function getAppoinmentDelete(deletesummary) {
    const response = await con.AppoinmentDeleteAPI(deletesummary);
    console.log(response)
    if (response['status'] === true) {
      let afterAdded = summary.filter(item => item.id !== deletesummary);
      setSummary(afterAdded);
      toast.success(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });
    }
    else {
      toast.error(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });

    }
  }


  async function appointmentListApi(id) {
    const response = await con.appointmentList();
    console.log(response);
    if (response['status'] === true) {


      // const filteredId = data.data.filter((item) => item.patient_id === id);
      const filteredId = response.data.filter((item) => item.patient_id === id).reverse();
      console.log(filteredId)

      // setAppointmentList(filteredId);

      let localList1 = [];
      for (let i1 of filteredId) {
        let li1 = [JSON.parse(i1['patient_treatment'])];

        // i1.patient_treatment = [JSON.parse(i1['patient_treatment'])];
        // console.log(i1)
        if (li1 !== null) {
          for (let item of li1) {
            item['id'] = i1['id'];
            item['appointment_date'] = i1['appointment_date'];
            item['created_at_1'] = i1['created_at'];
            item['treatment_id'] = i1['treatment_id']
            item['service_id'] = i1['id']
            item['status'] = i1['status']
            item['doctor_first_name'] = i1['doctor_first_name']
            item['appointment_status'] = i1['appointment_status']
            item['inprogress_date'] = i1['inprogress_date']
            item['complete_date'] = i1['complete_date']


          }
          localList1.push(...li1);
        }

      }
      setAppointmentList(localList1);

    } else {
      console.log("error")
    }
  }




  const calculateTotalCost = (prices, discount) => {
    const total = prices.reduce((acc, price) => acc + price, 0);
    const discountPercentage = parseFloat(discount);
    const discountedTotal = total - (total * (discountPercentage / 100));
    return discountedTotal.toFixed(2);
  };


  function bookBtn(t, index) {
    t['index_1'] = index;
    console.log(summary)
    setSummaryT(t)
    setOpen(true)
  }

  // function bookBtn(t, index) {
  //   summary[index]['is_booked'] = true;
  //   const gen = summary.filter((t) => t.is_booked !== true)
  //   const gen1 = summary.filter((t) => t.is_booked === true)
  //   setAppointmentList([...appointmentList], gen1);
  //   setSummary([...summary], gen);

  // }
const[apStatus, setApStatus]=useState("")
  function ResudBtn(tre, index) {
    tre['index_1'] = index;
    setSummaryT(tre)
    setApStatus(tre.appointment_status)
    setOpen(true)
  }

  const [treatmentBookedDate, setTreatmentBooked] = useState([])
  const dateOnly = summary.map((t) => t.created_at_1)

  useEffect(() => {
    setTreatmentBooked([])
    for (const dateTimeString of dateOnly) {

      const dates = dateTimeString.split(' ');
      treatmentBookedDate.push(dates[0])
      setTreatmentBooked(treatmentBookedDate)

    }
  }, [])




  const [currentDateFormat, setCurrentDateFormat] = useState([])
  const [currentDateFormat2, setCurrentDateFormat2] = useState([])
  function date() {
    // const dateObj = new Date(selectedDate);


    // const formattedDate = dateObj.getFullYear() + '-' +
    //   String(dateObj.getMonth() + 1).padStart(2, '0') + '-' +
    //   String(dateObj.getDate()).padStart(2, '0');

    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const day2 = String(currentDate.getDate() + 1).padStart(2, '0');
    // const hours = String(currentDate.getHours()).padStart(2, '0');
    // const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    // const seconds = String(currentDate.getSeconds()).padStart(2, '0');

    // const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const formattedDate = `${year}-${month}-${day}`;
    const formattedDate2 = `${year}-${month}-${day2}`;
    setCurrentDateFormat(formattedDate)
    setCurrentDateFormat2(formattedDate2)
  }


  useEffect(() => {
    date()
  }, [])



  function handleSelectionChange(event, data, index) {
    console.log(handleSelectionChange);
    const selectedValue = event.target.value;
    console.log(selectedValue);
    console.log(data)
    const status = {
      appointment_status: selectedValue,
      id: data.id,
      inprogress_date: data.inprogress_date? data.inprogress_date : currentDateFormat,
      complete_date:selectedValue === "Completed" ?  currentDateFormat: "",
      // inprogress_date:selectedValue === "Inprogress" ? currentDateFormat : "",
      // inprogress_date:selectedValue === "Inprogress" ? data.inprogress_date? data.inprogress_date:currentDateFormat :
      //  selectedValue === "Completed"? data.inprogress_date? data.inprogress_date:currentDateFormat: "",
      // complete_date:selectedValue === "Completed" ?  currentDateFormat: "",
    };
    console.log(status)
    appointmentList[index].appointment_status = selectedValue;
    setAppointmentList([...appointmentList], appointmentList)
    addStatus(status);
  };


  async function addStatus(status) {
    const response = await con.StatusUpdateAppoinment((JSON.stringify(status)));
    console.log(response)
    if (response['status'] === true) {

      toast.success(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);

    }
    else {
      toast.error(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });

    }
  }

  console.log(appointmentList)


  return (
    <>

      {userRoleId !== null && userRoleId !== undefined && (userRoleId === '5' || userRoleId === '1' || userRoleId === '2' || userRoleId === '3') ?
        (
          <div className='tabtable' style={{ width: "98%", height: "auto", backgroundColor: "white", marginTop: "30px", borderRadius: "7px", padding: "10px", marginLeft: "20px" }}>
            <div style={{ marginBottom: '30px' }}>
              <GeneralDetails pData={patient} />
            </div>
            <h1>Planned Treatment</h1>
            {/* {(treatmentBookedDate.includes(currentDateFormat)) ?  */}
            <table className="bordered-table mt-4">
              <thead className="tablehead" >
                <tr style={{ borderRadius: "10px" }}>
                  <th>S.No</th>
                  <th>Date</th>
                  <th>Type/ Tooth No.</th>
                  {/* <th>Tooth No</th> */}
                  {/* <th>Tooth No</th> */}
                  <th>Diagnosis Name</th>
                  {/* <th>Treatment Plan</th>
              <th>Treatment Procedure</th> */}
                  <th>Treatment Method</th>
                  <th>Treatment Price</th>
                  {/* <th>Discount %</th> */}
                  <th>Total</th>
                  {userRoleId !== null && userRoleId !== undefined && (userRoleId === '5' || userRoleId === '1' || userRoleId === '2' || userRoleId === '3') ?
                    (
                      <th>Book</th>
                    ) : null}
                  {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') ?
                    (
                      <th>Edit</th>
                    ) : null}
                  {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') ?
                    (
                      <th>Delete</th>
                    ) : null}


                </tr>
              </thead>

              {/* {summary.map((t, index) => { */}
              {/* {summary.filter(t => t.status !== "booked").map((t, index) => { */}
              {summary.filter(t => t.status !== "booked").map((t, index) => {
                console.log(summary)
                const dateObject = new Date(t.created_at_1);
                const year = dateObject.getFullYear();
                const month = String(dateObject.getMonth() + 1).padStart(2, '0');
                const day = String(dateObject.getDate()).padStart(2, '0');
                const formattedDate = `${day}-${month}-${year}`;
                return (


                  <tbody>


                    <tr>


                      <td>{index + 1}</td>
                      {/* <td>{t.created_at_1 !== undefined ? t.created_at_1 : "-"}</td> */}
                      <td>{formattedDate}</td>

                      {/* <td style={{ textTransform: "uppercase" }}><b>{t.type}</b></td> */}

                      <td>{t.type === 'tooth' ? <p>{t.tooth}</p> : <b>{t.type}</b>}</td>

                      <td>

                        {
                          t.problem !== undefined ? t.problem.name : "-"
                        }

                      </td>
                      {/* <td>
                            {t.items !== undefined ?

                              t.items.map((t) => t.name).join(", ") : "-"
                            }
                            </td>
                            <td>
                              {t.itemsP !== undefined ?

                                t.itemsP.map((t) => t.sname).join(", ") : "-"
                              }
                            </td> */}
                      <td>
                        {t.itemsPr !== undefined ?

                          t.itemsPr.map((t) => t.mname).join(", ") : "-"
                        }
                      </td>
                      <td>

                        {t.itemsPr !== undefined ?

                          t.itemsPr.map((t) => t.price).join(", ") : "-"
                        }
                      </td>
                      {/* <td>
                            {t.discounts !== undefined? t.discounts:"-"}
                            
                          </td> */}
                      <td>

                        {t.discountPrice !== undefined ? t.discountPrice : "-"}
                      </td>
                      {userRoleId !== null && userRoleId !== undefined && (userRoleId === '5' || userRoleId === '1' || userRoleId === '2' || userRoleId === '3') ?
                        (
                          <td>
                            {/* {(t.is_booked !== undefined && t.is_booked === true) ? "Booked" : <button type="button" class="btn btn-primary my-5" aria-hidden="true" onClick={() => bookBtn(t, index)}
                                data-toggle="modal"
                                data-target="#book" style={{ cursor: "pointer", backgroundColor: "#145BD9" }}
                              ><span className="me-1" style={{ fontSize: "20px" }}></span>Book</button>
                              } */}
                            {/* {(t.is_booked !== undefined && t.is_booked === true) ? null : ( */}
                            <button
                              type="button"
                              className="btn btn-primary my-5"
                              aria-hidden="true"
                              onClick={() => bookBtn(t, index)}
                              data-toggle="modal"
                              data-target="#book"
                              style={{ cursor: "pointer", backgroundColor: "#145BD9" }}
                            >
                              <span className="me-1" style={{ fontSize: "20px" }}></span>
                              Book
                            </button>
                            {/* // )} */}

                            <div>



                              {open && <section>
                                <div className="container">


                                  <div className="modal fade" id="book" role="dialog">
                                    <div className="po_sc_in">

                                      <div className="modal-content">
                                        <div className="modal-header">
                                          <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>Book Appoinment</h4>
                                          <button type="button" className="close" style={{ marginRight: "-250px", color: "blue", fontSize: "30px" }} data-dismiss="modal">
                                            ×
                                          </button>

                                        </div>
                                        <div className="modal-body">
                                          <Book pData={patient} doc={doctor} sum={summaryT} info={treatmentId} setStatus={set} />
                                        </div>
                                        <div className="modal-footer">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>}
                            </div>
                          </td>

                        ) : null}
                      {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') ?
                        (
                          <td>
                            {/* <span className="lis" aria-hidden="true"
                              data-toggle="modal"
                              data-target="#edittreatmentmethod"
                            // onClick={() => handleForEditButtonClick(inventory)}
                            > */}
                            {/* <span style={{ justifyContent: 'center' }}>// */}
                            <RiEdit2Line className="edit_icon" aria-hidden="true"
                              data-toggle="modal"
                              onClick={() => handleForEdit(t, index)}
                              data-target="#edittreatmentmethod"
                            />
                            <section>
                              <div className="container">
                                <div className="modal fade" id="edittreatmentmethod" role="dialog">
                                  <div className="po_sc_in">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>Edit Treatment Method</h4>
                                        <button type="button" className="close popclose" style={{ color: "blue", fontSize: "30px" }} data-dismiss="modal">
                                          ×
                                        </button>
                                      </div>

                                      <div className="modal-body">
                                        <form >
                                          {/* <div className="row" style={{ paddingLeft: "50px", paddingRight: "50px" }}>
                      <div className="col-md-6 mb-sm-7 mb-4">
                        <label className="form-label">
                          <span className="required">Treatment Method:</span>
                        </label>
                        <input name="material_name" type="text" className="form-control"
                          placeholder="Material Name"
                          required />
                      </div>
                    </div> */}
                                          <TreatmentPlanForm
                                            data={formData}
                                            edit={editData}
                                            patient={patient}
                                            treatment={t}
                                            // data={summary.filter((t, index) => index === editIndex)[0]} 
                                            fun={update} index={index} form={closeForm} dpValue={firstDropDown} />
                                          {/* <button type="submit" class="btn btn-primary " style={{ width: "150px", margin: "auto", display: "block" }}>Submit</button> */}
                                        </form>
                                      </div>
                                      <div className="modal-footer">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            {/* </span> */}
                          </td>
                        ) : null}
                      {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') ?
                        (
                          <td>
                            <RiDeleteBin2Line
                              className="delete_icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => getAppoinmentDelete(t.id)}
                            />
                          </td>
                        ) : null}
                    </tr>


                    <hr />
                  </tbody>

                );
              })}


            </table>
            {/* : "" } */}

          </div>
        ) : null}

      <div className='tabtable' style={{ width: "98%", height: "auto", backgroundColor: "white", marginTop: "30px", marginBottom: "30px", borderRadius: "7px", padding: "10px", marginLeft: "20px" }}>

        <h1>Scheduled / Completed Treatment</h1>
        <table className="bordered-table mt-4">
          <thead className="tablehead" >
            <tr style={{ borderRadius: "10px" }}>
              <th>S.No</th>
              <th>Date</th>
              <th>Type/ Tooth No.</th>
              {/* <th>Tooth No</th> */}
              <th>Diagnosis Name</th>
              {/* <th>Treatment Plan</th>
              <th>Treatment Procedure</th>
               */}
              <th>Doctor Name</th>
              {/* <th>Treatment Price</th> */}
              <th>Treatment Method</th>
              {/* <th>Discount %</th> */}
              <th>Total</th>
              {userRoleId !== null && userRoleId !== undefined && (userRoleId === '5' || userRoleId === '1' || userRoleId === '2' || userRoleId === '3') ?
                (
                  <th>Book</th>
                ) : null}
              {userRoleId !== null && userRoleId !== undefined && (userRoleId === '5' || userRoleId === '1' || userRoleId === '2' || userRoleId === '3') ?
                (
                  <th>Status</th>
                ) : null}
              {userRoleId !== null && userRoleId !== undefined && (userRoleId === '4') ?
                (
                  <th>Status</th>
                ) : null}


            </tr>
          </thead>

          {appointmentList.map((tre, index) => {
            // if (tre.appointment_status !== "Completed") {
            const dateObject = new Date(tre.appointment_date);
            const year = dateObject.getFullYear();
            const month = String(dateObject.getMonth() + 1).padStart(2, '0');
            const day = String(dateObject.getDate()).padStart(2, '0');
            // const formattedDate = `${year}-${month}-${day}`;
            const formattedDate = `${day}-${month}-${year}`;
            console.log(tre)

            return (

              <tbody>


                <tr>


                  <td>{index + 1}</td>
                  {/* <td>{tre.appointment_date !== undefined ? tre.appointment_date : "-"}</td> */}
                  <td>{formattedDate}</td>

                  {/* <td style={{ textTransform: "uppercase" }}><b>{tre.patient_treatment[0].type}</b></td> */}

                  <td>{tre.type === 'tooth' ? <p>{tre.tooth}</p> : <b>{tre.type}</b>}</td>

                  <td>

                    {
                      tre.problem !== undefined ? tre.problem.name : "-"
                    }

                  </td>
                  <td>
                    {tre.doctor_first_name}
                  </td>
                  {/* <td>

                    {tre.itemsPr !== undefined ?
                      // tre.treatmentProcedure method_price
                      // tre.itemsPr.map((t) => parseFloat(tre.price)).reduce((acc, price) => acc + price, 0) : "-"
                      // tre.itemsPr.map((t) => tre.price).join(", ") : "-"
                      tre.itemsPr.map((t) => { return t.price; }).join(', ') : "-"
                    }
                  </td> */}
                  <td>
                    {tre.itemsPr !== undefined ?

                      tre.itemsPr.map((t) => { return t.mname }).join(", ") : "-"
                    }
                  </td>
                  {/* <td> */}
                  {/* {tre.discounts !== undefined? tre.discounts:"-"} */}
                  {/* <input type="text" value={discounts[index] !== undefined ? discounts[index] : ""} onChange={(e) => {
              const updatedDiscounts = [...discounts];
              updatedDiscounts[index] = e.targetre.value;
              setDiscounts(updatedDiscounts);
            }} maxLength="3" /> */}
                  {/* </td> */}
                  {/* <td> {tre.discounts !== undefined? tre.discounts:"-"}</td> */}
                  <td>
                    {/* {calculateTotalCost(
                      tre.itemsPr !== undefined
                        ? tre.itemsPr.map((t) => parseFloat(tre.price))
                        : [],
                      discounts
                    )} */}
                    {/* {tre.itemsPr !== undefined ? tre.price : "-"} */}
                    {tre.discountPrice !== undefined ? tre.discountPrice : "N/A"}
                  </td>

                  {userRoleId !== null && userRoleId !== undefined && (userRoleId === '5' || userRoleId === '1' || userRoleId === '2' || userRoleId === '3') ?
                    (
                      <td>
                        {/* {(tre.appointment_status !== "Completed") ?
                      (tre.is_booked !== undefined && tre.is_booked === true) ? "Booked" : <button type="button" class="btn btn-primary my-5" aria-hidden="true" onClick={() => ResudBtn(tre, index)}
                        data-toggle="modal"
                        data-target="#rescheduleappoi" style={{ cursor: "pointer", backgroundColor: "#145BD9" }}
                      ><span className="me-1" style={{ fontSize: "20px" }}></span>Reschedule</button>
                      : <em style={{ color: "green" }}>Completed</em>} */}
                        {/* {
                          tre.appointment_status === "Rescheduled" ? (
                            <em style={{ color: "blue" }}>Rescheduled</em>
                          ) : (
                            tre.appointment_status !== "Completed" ? (
                              tre.is_booked !== undefined && tre.is_booked === true ? (
                                "Booked"
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary my-5"
                                  aria-hidden="true"
                                  onClick={() => ResudBtn(tre, index)}
                                  data-toggle="modal"
                                  data-target="#rescheduleappoi"
                                  style={{ cursor: "pointer", backgroundColor: "#145BD9" }}
                                >
                                  <span className="me-1" style={{ fontSize: "20px" }}></span>Reschedule
                                </button>
                              )
                            ) : (
                              <em style={{ color: "green" }}>Completed</em>
                            )
                          )
                        } */}
                        { tre.appointment_status === 'Next' ? (
                            <em style={{ color: "blue" }}>Appoinment Completed</em>
                          ) :
                          tre.appointment_status === ("Rescheduled")   ? (
                            <em style={{ color: "blue" }}>Rescheduled</em>
                          ) : tre.appointment_status === "Completed" ? (
                            <em style={{ color: "green" }}>Completed</em>
                          ) : tre.appointment_status === "Cancel" ? (
                            <em style={{ color: "Red" }}>Cancelled</em>
                          ) : tre.is_booked !== undefined && tre.is_booked === true ? (
                            "Booked"
                          ) : (
                            <button
                              type="button"
                              className="btn btn-primary my-5"
                              aria-hidden="true"
                              onClick={() => ResudBtn(tre, index)}
                              data-toggle="modal"
                              data-target="#rescheduleappoi"
                              style={{ cursor: "pointer", backgroundColor: "#145BD9" }}
                            >
                              <span className="me-1" style={{ fontSize: "20px" }}></span>{tre.appointment_status === "Inprogress"? 'Next':'Reschedule'}
                            </button>
                          )
                        }


                        <div>



                          {open && <section>
                            <div className="container">


                              <div className="modal fade" id="rescheduleappoi" role="dialog">
                                <div className="po_sc_in">

                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>{apStatus === "Inprogress"? 'Next Appointment':'Reschedule Appointment'}</h4>
                                      <button type="button" className="close" style={{ marginRight: "-250px", color: "blue", fontSize: "30px" }} data-dismiss="modal">
                                        ×
                                      </button>

                                    </div>
                                    <div className="modal-body">
                                      {/* <Book pData={patient} doc={doctor} sum={summaryT} info={treatmentId} setStatus={setres} /> */}
                                      <RescheduleAppoi pData={patient} doc={doctor} sum={summaryT} info={treatmentId} setStatus={setres} apStatus={apStatus} />
                                    </div>
                                    <div className="modal-footer">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>}
                        </div>
                      </td>
                    ) : null}
                  {userRoleId !== null && userRoleId !== undefined && (userRoleId === '5' || userRoleId === '1' || userRoleId === '2' || userRoleId === '3') ?
                    (
                      <td>
                        {/* <select
                          className="form-select"
                          id="sel10"
                          style={{ fontSize: 14, height: 34, width: "100%" }}
                          required
                          onChange={(e) => {

                            handleSelectionChange(e, tre, index);
                          }}
                          value={tre.appointment_status}
                          disabled={tre.appointment_status === 'Rescheduled'}
                        >
                          <option value="Open">Open</option>
                          <option value="Pending">Pending</option>
                          <option value="In-Progress">In-Progress</option>
                          <option value="Completed">Completed</option>

                        </select> */}
                        {/* 
                        {tre.appointment_status !== 'Rescheduled' ? (
                          <select
                            className="form-select"
                            id="sel10"
                            style={{ fontSize: 14, height: 34, width: "100%" }}
                            required
                            onChange={(e) => {
                              handleSelectionChange(e, tre, index);
                            }}
                            value={tre.appointment_status}
                          >
                            <option value="Open">Open</option>
                            <option value="Pending">Pending</option>
                            <option value="Inprogress">In-Progress</option>
                            <option value="Completed">Completed</option>
                            <option value="Cancel">Cancel</option>
                          </select>
                        ) : (
                          <span>Rescheduled</span>
                        )}
                      </td> */}

                        {
                        tre.appointment_status !== 'Next'? (
                        tre.appointment_status !== 'Rescheduled'   ? (
                          tre.appointment_status !== 'Cancel' ? (
                            tre.appointment_status !== 'Completed' ? (
                              <select
                                className="form-select"
                                id="sel10"
                                style={{ fontSize: 14, height: 34, width: "100%" }}
                                required
                                onChange={(e) => {
                                  handleSelectionChange(e, tre, index);
                                }}
                                value={tre.appointment_status}
                              >
                                <option value="Open">Open</option>
                                <option value="Pending">Pending</option>
                                <option value="Inprogress">In-Progress</option>
                                {/* <option value="Next">Next</option> */}
                                <option value="Completed">Completed</option>
                                <option value="Cancel">Cancel</option>
                                {/* <option value="Rescheduled">Rescheduled</option> */}
                              </select>

                            ) : (
                              <span>Completed</span>
                            )
                          ) : (
                            <span>Cancelled</span>
                          )
                        ) : (
                          <span>Rescheduled</span>

                        )
                     ) : (
                        <span>Appointment completed</span>

                      )}
                      </td>


                    ) : null}
                  {userRoleId !== null && userRoleId !== undefined && (userRoleId === '4') ?
                    (
                      <td>
                        {tre.appointment_status}
                      </td>
                    ) : null}

                </tr>

                <hr />
              </tbody>

            );
            // }
            return null; // Hide the row if the appointment_status is "completed"
          })}
          {/* // })} */}

        </table>

      </div>

    </>

  )
}
export default DiagnosisSummary