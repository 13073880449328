import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as con from '../../repository/api_calls'
import * as cons from '../../repository/urls'
import { useReducer } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import MiniCalendar from 'components/calendar/MiniCalendar';


// function reducer(state, action) {
//   if (action.type === 'set_value') {
//     state[action.key] = action.value;
//     return {
//       patient_id: state.patient_id,
//       id: state.id,
//       paid_amt: state.paid_amt
//     };
//   }

// } 




const Payment = (props) => {

  // const [state, dispatch] = useReducer(reducer, { patient_id: (props.patient_id), id: '', paid_amt: '', })



  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState([]);
  const [inputEditValues, setInputEditValues] = useState([]);
  const [inputConFee, setConFee] = useState([]);
  const [inputDiscount, setDiscountChange] = useState('');
  const [listData, setListData] = useState([]);
  const [datePicker1, setdatePicker1] = useState([])
  const [listPaidData, setListPaidData] = useState([])
  const [discount, setDiscount] = useState('')
  const [loading, setLoading] = useState(false)

  const userRoleId = localStorage.getItem('role_id');

  // const patient = props.pData
  const patient = {
    data: props.pData,
    id: props.patient_id,
    reg_no:props.reg
  };

  // const date = 'May 05';

  // useEffect(() => {
  //   localStorage.setItem('date', date);
  // }, []);

  const tableData = [
    {
      date: 'May 05',
      type: 'General',
      diagnosis: 'Cavities',
      treatmentPlan: 'Complete Edentulism',
      appointmentCharge1: 1000,
      appointmentCharge2: 200,
      appointmentCharge3: 150,
      appointmentCharge4: 500,
      doctor: 'Santhanam',
      total: 9000,
      discount: 7,
      amount: '',
      balance: '',
      nextValue: '',
      status: '',


    },
    // Add more objects to the array as needed
  ];
  useEffect(() => {
    localStorage.setItem('tableData', JSON.stringify(tableData));
  }, []);

  tableData.forEach((rowData) => {
    // Calculate the discount amount
    const total = (rowData.appointmentCharge1 + rowData.appointmentCharge2 + rowData.appointmentCharge3 + rowData.appointmentCharge4)
    const discountAmount = (rowData.total * rowData.discount) / 100;

    // Calculate the result amount
    const resultAmount = rowData.total - rowData.amount;

    // Calculate the percentage of the total based on the discount value
    const percentage = (discountAmount / rowData.total) * 100;
    const balance = (resultAmount - percentage);

    // Update the 'amount' and 'percentage' properties in the rowData object
    rowData.total = total;
    rowData.amount = discountAmount;
    rowData.percentage = percentage;
    rowData.balance = balance;
    rowData.result = rowData.total - rowData.amount;
    rowData.nextValue = rowData.result - inputValues;
  });

  console.log(listData)
  const handleInputChange = (event, index) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = event.target.value;
    setInputValues(newInputValues);
  };
  const handleEditInputChange = (event, index) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = event.target.value;
    setInputEditValues(newInputValues);
  };
  const handleDiscountChange = (event, index) => {
    const newInputValues = [...inputDiscount];
    newInputValues[index] = event.target.value;
    setDiscountChange(newInputValues);
  };

  const handleConFeeChange = (event, index) => {
    const newInputValues = [...inputConFee];
    newInputValues[index] = event.target.value;
    setConFee(newInputValues);
  };

  function calculateDifference(amount, pay) {
    // setDiscountedPrice(amount - (pay || 0))
    // return calculateDifference1(amount, pay);
    return amount - (pay || 0);
  };

  const calculateAmount = (total, discount) => {
    if (discount !== undefined && discount !== null) {
      const discountAmount = (total * discount) / 100;
      return Math.round(total - discountAmount);
    } else {
      return Math.round(total);
    }
  };

  const calculateFlatrate = (total, rate) => {
    return total - (rate || 0)
  } 
  // const calculateAmount = (total, discount) => {
  //   // Ensure that total, discount, and confee are valid numbers
  //   const totalAmount = parseFloat(total) || 0;
  //   const discountAmount = parseFloat(discount) || 0;


  //   if (!isNaN(totalAmount) && !isNaN(discountAmount)) {
  //     // Check if discount is provided and not equal to 0
  //     if (discountAmount !== 0 && discountAmount !== null && discountAmount !== undefined) {
  //       const discountedAmount = totalAmount - (totalAmount * discountAmount) / 100;
  //       return discountedAmount;
  //     } else {
  //       // If discount is 0 or not provided, just add the consultation fee
  //       return totalAmount;
  //     }
  //   } else {
  //     console.error("Invalid input values for calculation");
  //     return 0; // Return a default value or handle the error as needed
  //   }
  // };

  

  // function calculateDifference1(amount, pay) {
  //   // setDiscountedPrice(amount - (pay || 0))
  //   return amount - (pay || 0);
  // };

  // const datep = (date) => {
  //   setdatePicker1(date)
  // }

  // useEffect(() => {
  //   treatmentListToPay()
  // }, [datePicker1])

  // function treatmentListToPay() {
  //   listToPay()
  // }

  // useEffect(() => {
  //   paymentlistToPay
  // },)
  useEffect(() => {
    paymentlistToPay();
  }, [])
  // function paymentData(){

  //   const data = {
  //     "date":"2023-09-06",
  //      "patient_id": patient.id,
  //      "treatment_id":"#TRE - 2061210506",
  //      "discount":10,
  //      "paid_amt": "100",
  //      "status": "pending",
  //      "total_amt": 1000
  //  }

  // }

  const [currentDateFormat, setCurrentDateFormat] = useState([])
  // function currentDate() {

  //   const currentDate = new Date();

  //   const year = currentDate.getFullYear();
  //   const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  //   const day = String(currentDate.getDate()).padStart(2, '0');
  //   const formattedDate = `${year}-${month}-${day}`;
  //   setCurrentDateFormat(formattedDate)

  // }
  // useEffect(() => {
  //   currentDate()
  // }, [])

  async function storePayment(paymentData) {
    console.log(paymentData)
    setLoading(!loading)
    const responseadd = await con.storePayment(paymentData);
    // console.log("return = " + responseadd['data'])
    if (responseadd['status'] == true) {
      // console.log(responseadd['status'])
      console.log(responseadd['data'])
      setListPaidData(responseadd['data'])
      toast.success(responseadd['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });
      // window.location.reload();
      setLoading(!loading)
    }
    else {
      console.log(responseadd['status'])
      toast.error(responseadd['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });

    }
  }

  console.log(listPaidData)

  // ---------------------------------- Payment select date list -----------

  async function paymentlistToPay() {
    if (patient && patient.id) {
      const responseadd = await con.listToPay({
        // "date": datePicker1,
        "patient_id": patient.id,
      });
      console.log(responseadd)
      console.log('ooooooooooooooooooooooooooooooooooooo')
      if (responseadd['status'] == true) {
        console.log(responseadd.data)
        setListData(responseadd.data.reverse())
        // toast.success(responseadd['message'], {
        //   position: toast.POSITION.TOP_RIGHT,
        //   className: 'foo_bar_log_Success'
        // });
        // responseadd.data.forEach((payment) => {
        //   updatePatientPay(payment);
        // });
        // console.log(responseadd.data)
      }
      else {
        console.log(responseadd['status'])
      }
    } else {
      console.log("Patient or patient.id is undefined");
    }
  }


  // async function paymentlistToPay() {
  //   const response = await con.listToPay({
  //     "patient_id": state.patient_id
  //   });
  //   console.log(response)
  //   if (response['status'] === true) {
  //     setListData(response['data']);
  //   }
  // }


  // JSON.parse(listData.services[0].patient_treatment).problem.mname
  // console.log(listData.map((t) => t.services.map((tc) => tc.patient_treatment)))
  console.log(datePicker1)

  const submitEditPay = (event) => {
    event.preventDefault();
    // updatePatientPay(state);
  }

  // // ---------------------update api ------------

  async function updatePatientPay(payment) {
    setLoading(!loading)
    const response = await con.UpdatePatientpaymentAPI((JSON.stringify(payment)));
    console.log(response)
    if (response['status'] === true) {

      // dispatch({ type: 'set_value', value: '', key: 'id' })
      // dispatch({ type: 'set_value', value: '', key: 'paid_amt' })

      toast.success(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });
      setLoading(!loading)
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
    else {
      toast.error(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });
    }
  }


  // const [selectedDiscountType, selectedIndex] = discount.split('-');
  return (
    <React.Fragment>
      {/* <div className="grid grid-cols-1 rounded-[20px]">
        <MiniCalendar date={datep} />
      </div> */}

      <div className='paymentTable tabtablediagno' style={{ width: "auto", height: "auto", backgroundColor: "white", marginTop: "30px", marginBottom: "30px", borderRadius: "7px", padding: "10px", marginLeft: "20px", marginRight: "20px !important" }}>
        <h3 className="text-center" style={{ fontWeight: "bold", margin: "20px", fontSize: "1.8rem", }}><b>Payment Details</b></h3>

        <table className="bordered-table tablescroll" >
          <thead className="tablehead">
            <tr style={{ borderRadius: "10px" }}>
              <th>Date</th>
              <th>Type</th>
              <th>Diagnosis</th> 
              <th style={{ minWidth: '200px' }}>Treatment Done</th>
              <th>Total</th>
               {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') ?    <th>Discount Type</th> : null}
              <th>Discount</th>
              <th>Discounted Amount</th>
              {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1' || userRoleId === '2' || userRoleId === '3' || userRoleId === '5') ?
                (
                  <th>Pay</th>
                ) : null}
              {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') ?
                (
                  <th>Edit Payment</th>
                ) : null}
              <th>Due</th>
              <th>Paid</th>
              <th>Status</th>
              <th>Invoice</th>
            </tr>
          </thead>
          <tbody>
            {listData.map((rowData, index) => (
              console.log(rowData),
              inputDiscount[index],
              < tr key={index} >
                <td>{rowData.date}</td>
                < td >
                  {
                    rowData.payment.map((t) => (t.services.map((p) => (
                      <span>{JSON.parse(p.patient_treatment).type === 'tooth' ? (
                        <span>{JSON.parse(p.patient_treatment).tooth}<br /></span>
                      ) : (
                        <span>general<br /></span>
                      )}
                      </span>
                    )

                    )))
                  }
                </td>
              
                <td style={{ minWidth: '170px' }}className='tdata'>{rowData.payment.map((t) => (t.services.map((p) => (
                  <span>{JSON.parse(p.patient_treatment).problem.name}<br /></span>
                )))


                )}</td>
             
                <td style={{ minWidth: '200px' }}>{rowData.payment.map((t) => t.services.map((p) => (
                  <span >{JSON.parse(p.patient_treatment)?.itemsPr?.map((t) => <tr>{t.mname}</tr>)} <br /></span>

                ))
                )}</td>

                <td >{rowData.payment.map((t) => t.total)}</td>
                {/* ------------------------------------------------- */}
                {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') ?
                  (
                    <td>
                    { (rowData.payment[0].already_paid_amount === null) ?
                     
                      <select
                            value={discount[index]}
                            onChange={(e) => {
                              const { value } = e.target;
                              const newDiscount = [...discount];
                              newDiscount[index] = value;
                              setDiscount(newDiscount);
                            }}>
                            <option value="">Discount type</option>
                            <option value="discount">Discount (%)</option>
                            <option value="flatrate">Flat Rate</option>
                          </select> 
                          
                          
                          :  rowData.payment.map((t) => t.discount_type) } </td>) : null
                        }

                {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') ?
                  (
                    <td>
                    
                     

                      {discount && rowData.payment[0].already_paid_amount === null ? <input
                        type="number"
                        value={inputDiscount[index] || ''}
                        onChange={(event) => handleDiscountChange(event, index)}
                        style={{ border: '2px solid #F5F5F5' }}
                      /> : rowData.payment.map((t) => t.discount)}

                    </td>
                  ) : null}



                {userRoleId !== null && userRoleId !== undefined && (userRoleId === '4' || userRoleId === '2' || userRoleId === '3') ?
                  (
                    <td>
                      {rowData.payment.map((t) => t.discount)}
                    </td>
                  ) : null}
               
                


             
                  <td>
 
  {rowData.payment[0].discount === null ? discount[index] === 'discount'?
    calculateAmount(
      rowData.payment.map((t) => t.total), inputDiscount[index])  
      
      :
       discount[index] === 'flatrate' ?
       calculateFlatrate(rowData.payment.map((t) => t.total),inputDiscount[index]) : rowData.payment.map((t) => t.total)
       : rowData['payment'][0]['discount_type'] === "discount" ?calculateAmount(
        rowData.payment.map((t) => t.total), rowData.payment.map((t) => t.discount)) : rowData.payment.map((t) => t.total) - rowData.payment.map((t) => t.discount)
    
  } 
</td>




              


                {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1' || userRoleId === '2' || userRoleId === '3' || userRoleId === '5') ?
                  (
                    <td>
                     
                      {(rowData.payment.map((t) => t.already_paid_amount) === 0 ? rowData.payment[0].discount === null ? 
                  discount[index] === 'discount'?
                  calculateAmount(
                    rowData.payment.map((t) => t.total), inputDiscount[index]) : discount[index] === 'flatrate' ? calculateFlatrate(rowData.payment.map((t) => t.total),inputDiscount[index]) : rowData.payment.map((t) => t.total)
                    : rowData['payment'][0]['discount_type'] === "discount" ?
                    calculateAmount(
                      rowData.payment.map((t) => t.total), rowData.payment.map((t) => t.discount)) : rowData['payment'][0]['discount_type'] === "flatrate" && rowData.payment.map((t) => t.total) - rowData.payment.map((t) => t.discount) :calculateDifference(
                        rowData.payment[0].discount === null ? 
                        discount[index] === 'discount'?
                        calculateAmount(
                          rowData.payment.map((t) => t.total), inputDiscount[index]) : discount[index] === 'flatrate' ? calculateFlatrate(rowData.payment.map((t) => t.total),inputDiscount[index]) : rowData.payment.map((t) => t.total) : rowData['payment'][0]['discount_type'] === "discount" ?
                          calculateAmount(
                            rowData.payment.map((t) => t.total), rowData.payment.map((t) => t.discount)) : rowData['payment'][0]['discount_type'] === "flatrate" && rowData.payment.map((t) => t.total) - rowData.payment.map((t) => t.discount), rowData.payment[0].already_paid_amount )  ) > 0 ? <input
                            type="number"
                            value={inputValues[index] || ''}
                            onChange={(event) => handleInputChange(event, index)}
                            style={{ border: '3px solid #F5F5F5' }}
                          />  : ""}

                    </td>
                  ) : null}
              
                {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') ?
                  (
                    <td>
                      <form onSubmit={submitEditPay}>
                        <span style={{ display: "flex" }}>
                          <input type='number'
                            value={inputEditValues[index]}
                            onChange={(event) => handleEditInputChange(event, index)}
                            required style={{ border: '3px solid #F5F5F5' }} />
                          <button className='btn btn-primary ms-3' type="submit" disabled={loading}
                            onClick={() => {
                              console.log(inputEditValues[index])
                              updatePatientPay({

                                "patient_id": patient.id,
                               
                                "id": rowData.payment[0].payment_id,
                                "paid_amt": inputEditValues[index]
                              })
                            }}
                          >{loading?"Submitting":"Submit"}</button>

                        </span>
                      </form>
                    </td>
                  ) : null}
               
                <td> 
                  {
                  rowData.payment[0].already_paid_amount === null ? rowData.payment[0].discount === null ? 
                  discount[index] === 'discount'?
                  calculateAmount(
                    rowData.payment.map((t) => t.total), inputDiscount[index]) : discount[index] === 'flatrate' ? calculateFlatrate(rowData.payment.map((t) => t.total),inputDiscount[index]) : rowData.payment.map((t) => t.total)
                    : rowData['payment'][0]['discount_type'] === "discount" ?
                    calculateAmount(
                      rowData.payment.map((t) => t.total), rowData.payment.map((t) => t.discount)) : rowData['payment'][0]['discount_type'] === "flatrate" && rowData.payment.map((t) => t.total) - rowData.payment.map((t) => t.discount) :
                      calculateDifference(
                        rowData.payment[0].discount === null ? 
                        discount[index] === 'discount'?
                        calculateAmount(
                          rowData.payment.map((t) => t.total), inputDiscount[index]) : discount[index] === 'flatrate' ? calculateFlatrate(rowData.payment.map((t) => t.total),inputDiscount[index]) : rowData.payment.map((t) => t.total) : rowData['payment'][0]['discount_type'] === "discount" ?
                          calculateAmount(
                            rowData.payment.map((t) => t.total), rowData.payment.map((t) => t.discount)) : rowData['payment'][0]['discount_type'] === "flatrate" && rowData.payment.map((t) => t.total) - rowData.payment.map((t) => t.discount), rowData.payment[0].already_paid_amount ) 
                    

                }
                  


                </td>
             
                <td>{rowData.payment.map((t) => t.already_paid_amount)}</td>
              
                <td>
                   {(rowData.payment.map((t) => t.already_paid_amount) === 0 ? rowData.payment[0].discount === null ? 
                  discount[index] === 'discount'?
                  calculateAmount(
                    rowData.payment.map((t) => t.total), inputDiscount[index]) : discount[index] === 'flatrate' ? calculateFlatrate(rowData.payment.map((t) => t.total),inputDiscount[index]) : rowData.payment.map((t) => t.total)
                    : rowData['payment'][0]['discount_type'] === "discount" ?
                    calculateAmount(
                      rowData.payment.map((t) => t.total), rowData.payment.map((t) => t.discount)) : rowData['payment'][0]['discount_type'] === "flatrate" && rowData.payment.map((t) => t.total) - rowData.payment.map((t) => t.discount) :calculateDifference(
                        rowData.payment[0].discount === null ? 
                        discount[index] === 'discount'?
                        calculateAmount(
                          rowData.payment.map((t) => t.total), inputDiscount[index]) : discount[index] === 'flatrate' ? calculateFlatrate(rowData.payment.map((t) => t.total),inputDiscount[index]) : rowData.payment.map((t) => t.total) : rowData['payment'][0]['discount_type'] === "discount" ?
                          calculateAmount(
                            rowData.payment.map((t) => t.total), rowData.payment.map((t) => t.discount)) : rowData['payment'][0]['discount_type'] === "flatrate" && rowData.payment.map((t) => t.total) - rowData.payment.map((t) => t.discount), rowData.payment[0].already_paid_amount )  ) > 0 ? <span className="badge_danger badge-danger">In-Due</span> : <span className="badge_success badge-success">No Due</span>}

                        
                </td>
                
                <td>
                  <button className='btn btn-primary' disabled={loading} onClick={() => {

                    let id = rowData.payment.map((t) => t.services)
                    


                    inputValues[index] !== undefined &&
                     storePayment({
                      // "service_id": rowData['services'][0]['service_id'],
                      // "service_id": rowData.payment.map((t) => t.services.map((s) => s.service_id)),
                      "date": rowData.date,
                      // "appointment_id": rowData.payment.map((t) => t.services.map((s) => s.appointment_id)),
                      "appointment_id": rowData.payment[0].services.map((s) => s.appointment_id),
                      "patient_id": patient.id,
                      "discount_type": rowData["payment"][0]["discount_type"] !== null? rowData["payment"][0]["discount_type"]:discount[index],
                      // "treatment_id": rowData.payment.map((t) => t.services.map((s) => s.treatment_id)),
                      "discount": rowData['payment'][0]['discount'] === null ? inputDiscount[index] : rowData['payment'][0]['discount'],
                      "already_paid": rowData['payment'][0]['already_paid_amount'],
                      // "discount": rowData.payment.map((t) => t.discount) === null ? inputDiscount[index] : rowData.payment.map((t) => { t.discount }),
                      "paid_amt": inputValues[index],
                      "discount_amt": (rowData.payment[0].discount === null ? discount[index] === 'discount'?
                      calculateAmount(
                        rowData.payment.map((t) => t.total), inputDiscount[index])  
                        
                        :
                         discount[index] === 'flatrate' ?
                         calculateFlatrate(rowData.payment.map((t) => t.total),inputDiscount[index]) : rowData['payment'][0]['total']
                        //  rowData.payment.map((t) => t.total)
                        // rowData.payment.map((t) => t.total) - inputDiscount[index]
                        // rowData.payment.map((t) => t.total) - inputDiscount[index]
                         : rowData['payment'][0]['discount_type'] === "discount" ?calculateAmount(
                          rowData.payment.map((t) => t.total), rowData.payment.map((t) => t.discount)) : rowData.payment.map((t) => t.total) - rowData.payment.map((t) => t.discount)),

                      "balance":
                      rowData.payment[0].already_paid_amount === null ? rowData.payment[0].discount === null ? 
                      discount[index] === 'discount'?
                      calculateAmount(
                        rowData.payment.map((t) => t.total), inputDiscount[index]) : discount[index] === 'flatrate' ? calculateFlatrate(rowData.payment.map((t) => t.total),inputDiscount[index]) :  rowData['payment'][0]['total']
                        : rowData['payment'][0]['discount_type'] === "discount" ?
                        calculateAmount(
                          rowData.payment.map((t) => t.total), rowData.payment.map((t) => t.discount)) : rowData['payment'][0]['discount_type'] === "flatrate" && rowData.payment.map((t) => t.total) - rowData.payment.map((t) => t.discount) :
                          calculateDifference(
                            rowData.payment[0].discount === null ? 
                            discount[index] === 'discount'?
                            calculateAmount(
                              rowData.payment.map((t) => t.total), inputDiscount[index]) : discount[index] === 'flatrate' ? calculateFlatrate(rowData.payment.map((t) => t.total),inputDiscount[index]) : rowData.payment.map((t) => t.total) : rowData['payment'][0]['discount_type'] === "discount" ?
                              calculateAmount(
                                rowData.payment.map((t) => t.total), rowData.payment.map((t) => t.discount)) : rowData['payment'][0]['discount_type'] === "flatrate" && rowData.payment.map((t) => t.total) - rowData.payment.map((t) => t.discount), rowData.payment[0].already_paid_amount ) 
                     ,
                      "status": rowData.payment[0].services[0].appointment_status,
                      "total_amt": calculateAmount(rowData.payment.map((t) => t.total), inputDiscount[index]),
                    }),
                     console.log(inputValues[index],discount[index])
                    navigate("/Patient-Invoice", {
                      state: {
                        rowData: rowData,
                        payingAmount: inputValues[index],
                        payInput: rowData.payment[0].already_paid_amount === null ? inputValues[index] : rowData.payment.map((t) => t.already_paid_amount),
                        // payInput: inputValues[index],
                        discountInput: rowData.payment[0].discount === null ? inputDiscount[index] : rowData.payment.map((t) => t.discount),
                        consultation_fee: rowData.payment.map((t) => t.consultation_fee) === (null || 0) ? inputConFee[index] : rowData.payment.map((t) => t.consultation_fee),
                        index: index,
                        patient: patient,
                        // discountTotal: calculateAmount(rowData.payment.map((t) => t.total), rowData.payment.map((t) => t.discount) === 0 ? inputDiscount[index] : rowData.payment.map((t) => t.discount), rowData.payment.map((t) => t.consultation_fee) === (null || 0) ? inputConFee[index] : rowData.payment.map((t) => t.consultation_fee)),
                        discountTotal: rowData.payment[0].discount === null ? discount[index] === 'discount'?calculateAmount(
                          rowData.payment.map((t) => t.total), inputDiscount[index])  
                          
                          : discount[index] === 'flatrate' ?
                          calculateFlatrate(rowData.payment.map((t) => t.total),inputDiscount[index]): rowData.payment.map((t) => t.total) : rowData['payment'][0]['discount_type'] === "discount" ?calculateAmount(
                            rowData.payment.map((t) => t.total), rowData.payment.map((t) => t.discount)) : rowData.payment.map((t) => t.total) - rowData.payment.map((t) => t.discount),
                        due:
                        rowData.payment[0].already_paid_amount === null ? rowData.payment[0].discount === null ? 
                  discount[index] === 'discount'?
                  calculateAmount(
                    rowData.payment.map((t) => t.total), inputDiscount[index]) : discount[index] === 'flatrate' ? calculateFlatrate(rowData.payment.map((t) => t.total),inputDiscount[index]) : rowData.payment.map((t) => t.total)
                    : rowData['payment'][0]['discount_type'] === "discount" ?
                    calculateAmount(
                      rowData.payment.map((t) => t.total), rowData.payment.map((t) => t.discount)) : rowData['payment'][0]['discount_type'] === "flatrate" && rowData.payment.map((t) => t.total) - rowData.payment.map((t) => t.discount) :
                      calculateDifference(
                        rowData.payment[0].discount === null ? 
                        discount[index] === 'discount'?
                        calculateAmount(
                          rowData.payment.map((t) => t.total), inputDiscount[index]) : discount[index] === 'flatrate' ? calculateFlatrate(rowData.payment.map((t) => t.total),inputDiscount[index]) : rowData.payment.map((t) => t.total) : rowData['payment'][0]['discount_type'] === "discount" ?
                          calculateAmount(
                            rowData.payment.map((t) => t.total), rowData.payment.map((t) => t.discount)) : rowData['payment'][0]['discount_type'] === "flatrate" && rowData.payment.map((t) => t.total) - rowData.payment.map((t) => t.discount), rowData.payment[0].already_paid_amount ) 
                      }
                    }
                    );
                  }
                  }>
                    {loading?"Submitting":'Generate Invoice'}
                  </button>
                </td>
                {/* ------------------------------------------------- */}
              </tr>
            ))}
          </tbody>
        </table>

      </div >



    </React.Fragment >
  )
}

export default Payment