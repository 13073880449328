import React, { useEffect, useState } from 'react';
import { FaCar, FaEnvelope, FaPhone } from "react-icons/fa";
import html2canvas from 'html2canvas';
import Logo from "assets/img/logo/logo.png";
import jsPDF from 'jspdf';
import { useLocation } from 'react-router-dom';


const PatientInvioce = () => {
    // console.log("props.location.state", props.location.state);
    const userRoleId = localStorage.getItem('role_id');
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    const data = location.state;
    // console.log(data.patient.data.user.first_name)
    console.log("Location State:", data);
    console.log("Location State:", data.rowData);
    const [service, setService] = useState([data])
    const patientuserdata = JSON.parse(localStorage.getItem("userdata"))
    console.log(patientuserdata); 

    const [currentDateFormat, setCurrentDateFormat] = useState([])
    function date() {

        const currentDate = new Date();

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        setCurrentDateFormat(formattedDate)

    }
    useEffect(() => {
        date()
    }, [])


    const downloadPDF = () => {
        const capture = document.querySelector('.actual-receipt');
        setLoader(true);

        html2canvas(capture).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // Adjust the dimensions as needed
            pdf.save('receipt.pdf');

            setLoader(false);
        });
    };

    return (

        <div>
            {/* {userRoleId == 1 && ( */}
            {(userRoleId == 1 || userRoleId == 2 || userRoleId == 3) && (
                <section className="invoicebaground">
                    <div className="wrapper">

                        <div className="background-container">

                            <div className="receipt-box">

                                {/* actual receipt */}
                                <div className="actual-receipt" style={{ width: 'auto', height: '900px' }}>

                                    {/* organization logo */}

                                    {/* organization name */}
                                    <div className="" >




                                        <div className="row" id='mtop'>

                                            <div className="col-md-12" style={{ marginTop: "0px" }} >

                                            
                                                <div className="col-md-2"> </div>
                                                <div className='col-md-8'>
                                                    <p className="invoice text-center mb-5">INVOICE </p>
                                                    <table className="bordered-table invoice-table" >

                                                        <tbody>
                                                            <tr>
                                                                <td className="patient-title" style={{ textAlign: "left" }}>Patient Name:  {data.patient.data.first_name}<br />Contact Number: {data.patient.data.phone}</td>
                                                                <td className="patient-title " style={{ textAlign: "right" }}>Invoice Date : {data.rowData.payment[0]?.updated_at} <br />Patient Id :{data.patient.data.patient_reg_no}</td>
                                                                {/* <td className="patient-title " style={{ textAlign: "right" }}>Invoice Date : {currentDateFormat} <br />Patient Id :{data.patient.data.patient_reg_no}</td> */}
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col-md-2"> </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                        </div>


                                    </div>

                                    <div className="row" id="mtop">
                                        <div className="col-md-12">
                                            <div className="col-md-2"> </div>
                                            <div className="col-md-8">

                                                <table className="bordered-table invoice-table" >
                                                    <thead style={{ backgroundColor: "black", color: "white" }}>
                                                        <tr>

                                                            <th className="" style={{ backgroundColor: "black", color: "white" }}>#</th>
                                                            <th className="" style={{ backgroundColor: "black", color: "white" }}>Date</th>
                                                            <th className="" style={{ backgroundColor: "black", color: "white" }}>Treatment ID</th>
                                                            <th className="" style={{ backgroundColor: "black", color: "white" }}>Tooth No</th>
                                                            <th className="" style={{ backgroundColor: "black", color: "white" }}>Treatment Done</th>
                                                            <th className="" style={{ backgroundColor: "black", color: "white" }}>Price</th>
                                                            <th className="" style={{ backgroundColor: "black", color: "white" }}>Total</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {service.map((rowData, index) => {

                                                            // const totalPaid = (data.payingAmount !== undefined ? data.payingAmount : 0) + (rowData.payInput !== undefined ? rowData.payInput : 0);
                                                            // const totalPaid = (data.payingAmount !== undefined ? data.payingAmount : '') + (rowData.rowData.payment[0].already_paid_amount !== undefined ? rowData.rowData.payment[0].already_paid_amount : 0);
                                                            // const totalPaid =
                                                            //     (data.payingAmount !== undefined ? parseFloat(data.payingAmount) : 0) +
                                                            //     (rowData.rowData.payment[0]?.already_paid_amount !== undefined ? parseFloat(rowData.rowData.payment[0].already_paid_amount) : 0);
                                                            const totalPaid =
                                                                (!isNaN(parseFloat(data.payingAmount)) ? parseFloat(data.payingAmount) : 0) +
                                                                (!isNaN(parseFloat(rowData.rowData.payment[0]?.already_paid_amount)) ? parseFloat(rowData.rowData.payment[0].already_paid_amount) : 0);

                                                            const due = rowData.due !== undefined ? parseFloat(rowData.due) : 0;
                                                            const payingAmount = !isNaN(parseFloat(data.payingAmount)) ? parseFloat(data.payingAmount) : 0;
                                                            const remainingDue = due - payingAmount;
                                                            return (
                                                                <>

                                                                    <tr key={index}>


                                                                        <td>{index + 1}</td>
                                                                        {/* <td>{rowData.rowData.services.map((p) => p.updated_at)}</td> */}
                                                                        <td>{rowData.rowData.date}</td>
                                                                        <td>{rowData.rowData.payment.map((t) => t.services.map((r) => <span> {r.treatment_id} <br /> </span>))}</td>
                                                                        {/* <td>{rowData.rowData.payment.map((p) => p.services.map((r) => <span>{JSON.parse(r.patient_treatment).itemsPr.map((t) => <span>{t.mname} <br /></span>)} </span>))}</td> */}

                                                                        <td>
                                                                            {rowData.rowData.payment.map((p) => (p.services.map((r) => (
                                                                                <span>{JSON.parse(r.patient_treatment).type === 'tooth' ? (
                                                                                    <span>{JSON.parse(r.patient_treatment).tooth}<br /></span>
                                                                                ) : (
                                                                                    <span>general<br /></span>
                                                                                )}
                                                                                </span>
                                                                            )))
                                                                            )}
                                                                        </td>

                                                                        <td>
                                                                            {rowData.rowData.payment.map((p) => (
                                                                                p.services.map((r) => {
                                                                                    const treatmentItems = JSON.parse(r.patient_treatment)?.itemsPr;
                                                                                    if (treatmentItems && Array.isArray(treatmentItems)) {
                                                                                        return treatmentItems.map((t, index) => (
                                                                                            <span key={index}>{t.mname} <br /></span>
                                                                                        ));
                                                                                    } else {
                                                                                        return null; // or some default content if itemsPr is not an array
                                                                                    }
                                                                                })
                                                                            ))}
                                                                        </td>

                                                                        {/* <td>{rowData.rowData.payment.map((p) => p.services.map((r) => <span>{JSON.parse(r.patient_treatment).itemsPr.map((t) => <span>{t.price} <br /></span>)} </span>))}</td> */}
                                                                        <td>
                                                                            {rowData.rowData.payment.map((p) => (
                                                                                p.services.map((r) => {
                                                                                    const treatmentItems = JSON.parse(r.patient_treatment)?.itemsPr;
                                                                                    if (treatmentItems && Array.isArray(treatmentItems)) {
                                                                                        return treatmentItems.map((t, index) => (
                                                                                            <span key={index}>{t.price} <br /></span>
                                                                                        ));
                                                                                    } else {
                                                                                        return null; // or some default content if itemsPr is not an array
                                                                                    }
                                                                                })
                                                                            ))}
                                                                        </td>
                                                                        <td>{rowData.rowData.payment.map((t) => t.total)}</td>
                                                                        {/* <td>{rowData.rowData.treatment_id}  </td>
                                                                    <td>{rowData.rowData.services.map((p) => <span>{JSON.parse(p.patient_treatment).itemsPr.map((t) => <span>{t.mname} <br /></span>)} </span>)}</td>
                                                                    <td>{rowData.rowData.services.map((p) => <span>{JSON.parse(p.patient_treatment).itemsPr.map((t) => <span>{t.price}  <br /></span>)}</span>)}</td>
                                                                    <td>{rowData.rowData.total}</td> */}
                                                                    </tr>

                                                                    <tr>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td className="tabledata" style={{ lineHeight: "30px" }} >Discount: <br />Discount Total: <br />
                                                                            {/* Paid Amount: <br /> */}
                                                                            Total Paid Amount:<br />Due Amount:</td>
                                                                        <td className="tableoutput" style={{ lineHeight: "30px" }} > {rowData.discountInput !== undefined ? rowData.discountInput : "No discount added"}<br />
                                                                            {rowData.discountTotal !== undefined ? rowData.discountTotal : "-"}<br />
                                                                            {/* {data.payingAmount !== undefined ? data.payingAmount : "Not Paid Yet"} <br /> */}
                                                                            {/* {rowData.payInput !== undefined ? rowData.payInput : "Not Paid Yet"} <br /> */}
                                                                            {/* {totalPaid !== undefined ? totalPaid : "Not Paid Yet"} < br /> */}
                                                                            {/* {totalPaid != null ? totalPaid : "Not Paid Yet"} <br /> */}
                                                                            {!isNaN(totalPaid) ? totalPaid : "Not Paid Yet"} <br />
                                                                            {/* {totalPaid} < br /> */}

                                                                            {/* {rowData.due !== undefined ? rowData.due : "0"} */}
                                                                            {remainingDue >= 0 ? remainingDue : "0"} <br />
                                                                        </td>

                                                                        {/* <td className="tableoutput" style={{ lineHeight: "30px" }} > {rowData.discountInput !== undefined ? rowData.discountInput : "No discount added"}<br />
                                                                        {rowData.rowData.payment.map((t) => t.discount) !== undefined ? rowData.rowData.payment.map((t) => t.discount) : "-"}<br />
                                                                        {rowData.rowData.payment.map((t) => t.paid_amt) !== undefined ? rowData.rowData.payment.map((t) => t.paid_amt) : "Not Paid Yet"} <br />
                                                                        {rowData.rowData.payment.map((t) => t.already_paid_amount) !== undefined ? rowData.rowData.payment.map((t) => t.already_paid_amount) : "Not Paid Yet"} <br />{rowData.due !== undefined ? rowData.due : "0"}</td> */}
                                                                    </tr>

                                                                </>
                                                            )
                                                        })
                                                        }
                                                    </tbody>

                                                </table>
                                            </div>
                                            <div className="col-md-2"></div>
                                        </div>
                                    </div>
                                </div>
                                {/* end of actual receipt */}
                            </div>
                        </div>
                    </div>
                </section>
            )}



            {userRoleId == 4 && (
                <section className="invoicebaground">
                    <div className="wrapper">

                        <div className="background-container">

                            <div className="receipt-box">

                                <div className="actual-receipt" style={{ width: 'auto', height: '700px' }}>

                                    <div className="main" >

                                        <div className="row" id="mtop">
                                            <div className="col-md-12">
                                                <div className="col-md-2"> </div>
                                                <div className="col-md-8">
                                                    <table className="bordered-table invoice-table" >

                                                        <tbody>
                                                            <td> <img src={Logo} alt="KDC" style={{ height: "123px" }} /></td>

                                                            <td style={{ textAlign: "right" }}>
                                                                <p className="title" >கிர்த்திகா பல் மருத்துவமனை</p>
                                                                <p className="title1"  >52/103 Tamil Sangam Road, Behind Anna Park</p>
                                                                <p className="title1" >Shankar Nagar, Salem - 636007</p>
                                                            </td>

                                                        </tbody>

                                                    </table>
                                                </div>
                                                <div className="col-md-2"></div>
                                            </div>
                                        </div>



                                        <div className="row" id='mtop'>

                                            <div className="col-md-12" >

                                                <div className="col-md-2"></div>

                                                <div className="col-md-4">
                                                    <p className="invoice">INVOICE </p>
                                                    <p className="patient-title">Patient Name: {patientuserdata.user.first_name} </p>
                                                    <p className="address-title" >Contact Number: {patientuserdata.user.phone} </p>
                                                </div>

                                                <div className="col-md-4">
                                                    <p className=" invoice-date">Invoice Date : {data.rowData.payment[0]?.updated_at} </p>
                                                    {/* <p className=" invoice-date">Invoice Date : {currentDateFormat} </p> */}
                                                    <p className='patient-id' >Patient Id : {data.patient.id}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                        </div>


                                    </div>

                                    <div className="row" id="mtop">
                                        <div className="col-md-12">
                                            <div className="col-md-2"> </div>
                                            <div className="col-md-8">
                                                <table className="bordered-table invoice-table" >
                                                    <thead >
                                                        <tr>

                                                            <th className="tableheading" >#</th>
                                                            <th className="tableheading">Completed Date</th>
                                                            <th className="tableheading">Treatment ID</th>
                                                            <th className="tableheading">Tooth No</th>
                                                            <th className="tableheading">Treatment Done</th>
                                                            <th className="tableheading">Price</th>
                                                            <th className="tableheading">Total</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {service.map((rowData, index) => {

                                                            const totalPaid =
                                                                (!isNaN(parseFloat(data.payingAmount)) ? parseFloat(data.payingAmount) : 0) +
                                                                (!isNaN(parseFloat(rowData.rowData.payment[0]?.already_paid_amount)) ? parseFloat(rowData.rowData.payment[0].already_paid_amount) : 0);

                                                            const due = rowData.due !== undefined ? parseFloat(rowData.due) : 0;
                                                            const payingAmount = !isNaN(parseFloat(data.payingAmount)) ? parseFloat(data.payingAmount) : 0;
                                                            const remainingDue = due - payingAmount;

                                                            return (
                                                                <>
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{rowData.rowData.date}</td>
                                                                        <td>{rowData.rowData.payment.map((t) => t.services.map((r) => <span> {r.treatment_id} <br /> </span>))}</td>
                                                                        <td>
                                                                            {rowData.rowData.payment.map((p) => (p.services.map((r) => (
                                                                                <span>{JSON.parse(r.patient_treatment).type === 'tooth' ? (
                                                                                    <span>{JSON.parse(r.patient_treatment).tooth}<br /></span>
                                                                                ) : (
                                                                                    <span>general<br /></span>
                                                                                )}
                                                                                </span>
                                                                            )))
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {rowData.rowData.payment.map((p) => (
                                                                                p.services.map((r) => {
                                                                                    const treatmentItems = JSON.parse(r.patient_treatment)?.itemsPr;
                                                                                    if (treatmentItems && Array.isArray(treatmentItems)) {
                                                                                        return treatmentItems.map((t, index) => (
                                                                                            <span key={index}>{t.mname} <br /></span>
                                                                                        ));
                                                                                    } else {
                                                                                        return null;
                                                                                    }
                                                                                })
                                                                            ))}
                                                                        </td>
                                                                        <td>
                                                                            {rowData.rowData.payment.map((p) => (
                                                                                p.services.map((r) => {
                                                                                    const treatmentItems = JSON.parse(r.patient_treatment)?.itemsPr;
                                                                                    if (treatmentItems && Array.isArray(treatmentItems)) {
                                                                                        return treatmentItems.map((t, index) => (
                                                                                            <span key={index}>{t.price} <br /></span>
                                                                                        ));
                                                                                    } else {
                                                                                        return null;
                                                                                    }
                                                                                })
                                                                            ))}
                                                                        </td>
                                                                        <td>{rowData.rowData.payment.map((t) => t.total)}</td>
                                                                    </tr>

                                                                    {/* <tr>

                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td className="tabledata" >Discount: <br />Discount Total: <br /> Total Paid Amount:<br />Due Amount:</td>
                                                                    <td className="tableoutput" > {rowData.discountInput !== undefined ? rowData.discountInput : "No discount added"}<br /> {rowData.discountTotal !== undefined ? rowData.discountTotal : "-"}<br /> {rowData.payInput !== undefined ? rowData.payInput : "Not Paid Yet"} <br />{rowData.due !== undefined ? rowData.due : "0"}</td>
                                                                    

                                                                </tr> */}
                                                                    <tr>

                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td className="tabledata" style={{ lineHeight: "30px" }} >Discount: <br />Discount Total: <br />
                                                                            {/* Paid Amount: <br /> */}
                                                                            Total Paid Amount:<br />Due Amount:</td>
                                                                        <td className="tableoutput" style={{ lineHeight: "30px" }} > {rowData.discountInput !== undefined ? rowData.discountInput : "No discount added"}<br />
                                                                            {rowData.discountTotal !== undefined ? rowData.discountTotal : "-"}<br />
                                                                            {/* {data.payingAmount !== undefined ? data.payingAmount : "Not Paid Yet"} <br /> */}
                                                                            {/* {rowData.payInput !== undefined ? rowData.payInput : "Not Paid Yet"} <br /> */}
                                                                            {/* {totalPaid !== undefined ? totalPaid : "Not Paid Yet"} < br /> */}
                                                                            {/* {totalPaid != null ? totalPaid : "Not Paid Yet"} <br /> */}
                                                                            {!isNaN(totalPaid) ? totalPaid : "Not Paid Yet"} <br />
                                                                            {/* {totalPaid} < br /> */}

                                                                            {/* {rowData.due !== undefined ? rowData.due : "0"} */}
                                                                            {remainingDue >= 0 ? remainingDue : "0"} <br />
                                                                        </td>

                                                                        {/* <td className="tableoutput" style={{ lineHeight: "30px" }} > {rowData.discountInput !== undefined ? rowData.discountInput : "No discount added"}<br />
{rowData.rowData.payment.map((t) => t.discount) !== undefined ? rowData.rowData.payment.map((t) => t.discount) : "-"}<br />
{rowData.rowData.payment.map((t) => t.paid_amt) !== undefined ? rowData.rowData.payment.map((t) => t.paid_amt) : "Not Paid Yet"} <br />
{rowData.rowData.payment.map((t) => t.already_paid_amount) !== undefined ? rowData.rowData.payment.map((t) => t.already_paid_amount) : "Not Paid Yet"} <br />{rowData.due !== undefined ? rowData.due : "0"}</td> */}
                                                                    </tr>
                                                                </>
                                                            )
                                                        })}
                                                    </tbody>

                                                </table>
                                            </div>
                                            <div className="col-md-2"></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="col-md-3"></div>
                                            <div className="col-md-8" id='emptydiv' ></div>
                                            <div className="col-md-1"></div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="col-md-2"></div>
                                            <div className="col-md-8 divider  " id="invoice-footer-sec" >
                                                <div className="col-md-3 " id='invoice-footer-div'><span className="invoice-footer-span" > <FaCar className='invoice-footer-icons' />kirthikadentalcare.in</span> </div><span className="line1" ></span>
                                                <div className="col-md-3 " id='invoice-footer-div'><span className="invoice-footer-span"><FaPhone className='invoice-footer-icons' />9089786756</span></div><span className="line2"></span>
                                                <div className="col-md-3 " id='invoice-footer-div'><span className="invoice-footer-mail" ><FaEnvelope className='invoice-footer-icons' />krithikadentalcare@gmail.com</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </div>



    );
}

export default PatientInvioce